import React from 'react'
import { NavLink } from 'react-router-dom'

function Footer() {
    return (
       <>
        <div className="footer">

            {/* ------------------ Footer -------------------------------------------- */}
            <footer className="page-footer font-small blue-grey lighten-5">  
                <div style={{ 'backgroundColor': '#21d192',width: '100%'}}>              
                  <div className="container">

                    {/* <!-- Grid row--> */}
                    <div className="row py-4 d-flex align-items-center">

                      {/* <!-- Grid column --> */}
                      <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                        <h6 className="mb-0">เชื่อมต่อกับเราบนเครือข่ายสังคม</h6>
                      </div>
                      {/* <!-- Grid column --> */}

                      {/* <!-- Grid column --> */}
                      <div className="col-md-6 col-lg-7 text-center text-md-right">

                        {/* <!-- Facebook class="fb-ic" target="_blank" --> */}
                        <a href="https://fb.me/SDent7Software" target="_blank" >
                        <i className="bi bi-facebook white-text mr-4" ></i> {/* <img src="assets\images\fblogo.png" alt="" /> */}
                        </a>
                        {/* <!-- Twitter --> */}
                        <a className="tw-ic">
                          <i className="bi bi-twitter white-text mr-4"> </i>
                        </a>
                        {/* <!-- Google +--> */}
                        <a className="gplus-ic">
                          <i className="bi bi-google white-text mr-4"> </i>
                        </a>
                        {/* <!--Linkedin --> */}
                        <a className="li-ic">
                          <i className="bi bi-linkedin white-text mr-4"> </i>
                        </a>
                        {/* <!--Instagram--> */}
                        <a className="ins-ic">
                          <i className="bi bi-instagram white-text"> </i>
                        </a>

                      </div>
                      {/* <!-- Grid column --> */}

                    </div>
                    {/* <!-- Grid row--> */}

                  </div>    
                </div>         
                {/* <!-- Footer Links --> */}
                <div className="container text-center text-md-left mt-5">

                  {/* <!-- Grid row --> */}
                  <div className="row mt-3 dark-grey-text">

                    {/* <!-- Grid column --> */}
                    <div className="col-md-3 col-lg-4 col-xl-3 mb-4">

                      {/* <!-- Content --> */}
                      <h6 className="text-uppercase font-weight-bold">WANPROSOFT CO.,LTD.</h6>
                      <hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{width: `60px`}} />
                      <p>ตารางการรักษามีรายการพร้อมใช้งานอยู่ 299 รายการ ซึ่งครอบคลุมการรักษาทั้งหมดในคลินิกทันตกรรม</p>

                    </div>
                    {/* <!-- Grid column --> */}

                    {/* <!-- Grid column --> */}
                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                      {/* <!-- Links --> */}
                      <h6 className="text-uppercase font-weight-bold">Products</h6>
                      <hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{width: `60px`}} />
                      <p>
                      <a className ="dark-grey-text" href> <NavLink exact to="/" ><span style={{color:'#000000'}}>โปรแกรมทันตกรรม</span></NavLink></a>
                      </p>
                      <p>
                      <a className ="dark-grey-text" href><NavLink exact to="/SBeauty" ><span style={{color:'#000000'}}>โปรแกรมความงาม</span></NavLink></a>
                      </p>
                      <p>
                      <a className ="dark-grey-text" href><NavLink exact to="/Spos" ><span style={{color:'#000000'}}>โปรแกรมร้านมินิมาร์ท</span></NavLink></a>
                      </p>
                      <p>
                      <a className ="dark-grey-text" href><NavLink exact to="/SCRM" ><span style={{color:'#000000'}}>โปรแกรมบริหารงานกำจัดปลวก</span></NavLink></a>
                      </p> 

                    </div>
                    {/* <!-- Grid column --> */}


                    {/* <!-- Grid column --> */}
                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                      {/* <!-- Links --> */}
                      <h6 className="text-uppercase font-weight-bold">Contact</h6>
                      <hr className="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style={{width: `60px`}} />
                      <p>
                      <i className ="bi bi-house-fill mr-3"></i>88/65 ปรัญญาโฮมทาวส์ ถนนเสรีไทย แขวงมีนบุรี เขตมีนบุรี กรุงเทพฯ 10510</p>
                      <p>
                      <i className ="bi bi-envelope-fill mr-3"></i> wanprosoft @outlook.com</p>
                      <p>
                      <i className ="bi bi-telephone-forward-fill mr-3"></i> Tel.081-583-7210</p>
                      <p>
                      <i className ="bi bi-telephone-forward-fill mr-3"></i> Tel.085-133-2576</p>

                    </div>
                  </div>

                </div>
                {/* <!-- Footer Links --> */}

                {/* <!-- Copyright --> */}
                <div className="DivBg" style={{ backgroundColor: 'LightGray'}}>
                  <div className="footer-copyright text-center text-black-50 py-3">
                     Copyright © 2027 Wanprosoft Co., Ltd. All rights reserved.
                  </div>
                </div>
                {/* <!-- Copyright --> */}    
              </footer>
              {/* <!-- Footer --> */}
              {/* <!-- Back top --> */}
              <div className="container-fix" align="right" style={{ backgroundColor: 'LightGray'}}>
                <a href="#" className="go-top">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
                </svg>
                </a>
              </div>
            {/* ------------------------------------------------------------------------------------------------------------- */}
        </div>
       </>  
    )
}

export default Footer
