// https://www.npmjs.com/package/react-responsive-carousel
import React,{ Component } from 'react';
import { Carousel } from 'react-responsive-carousel';

class DemoCarousel extends Component {
    render() {
        return (
            <Carousel autoPlay>
                <div>        
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img0B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>เข้าสู่ระบบ</p>
                </div>
                <div>        
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img01B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>หน้าจอหลัก</p>
                </div>
                <div>        
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img1B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>ประวัติคนไข้</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img2B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>ตารางการรักษา</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img3B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>ห้องทันตกรรม (แยกตามรหัสหมอ)</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img4B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>การเงิน (จ่ายได้ทุกแบบรวมทั้งประกันสังคม)</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img5B.jpg" />
                    <p className="legend" style={{'font-size':'14px', color:'#40D6EF'}}>ตารางนัดหมาย (บอกสถานะต่างๆของนัดหมาย เช่น ส่งคิว,แลป,ประเภทคนไข้,วันหยุด/วันทำงานของหมอ)</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img6B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>เครื่องมือวิเคราะห์ด้านการรักษาและเวชภัณฑ์</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img7B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>คำนวณ DF หมอ (ตั้งค่า Df หมอได้ทั้งแบบเปอร์เซ็นและแบบคงที่)</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img8B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>สต็อก</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img9B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>รับ-เบิก สินค้า</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img10B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>กราฟ-กำไร/ขาดทุน</p>
                </div>
                <div>
                    <img className="img-fluid" alt="" src="assets/images/screenshot-img11B.jpg" />
                    <p className="legend" style={{'font-size':'16px', color:'#40D6EF'}}>วิเคราะห์เพื่อส่งเสริมการขาย.CRM</p>
                </div>    
       
            </Carousel>
        );
    }
}

export default DemoCarousel