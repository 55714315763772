import React, { Component } from 'react'

class SBeauty extends Component {

    render() {
    return (
        <>
            <section className="Sbeauty-section bg-white" id="Sbeauty">
                    <div className="container">
                        <div className="row">

                            <div id="container_11641a92" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                                <div id="container_11641a92_padding" ><div className="textstyle2"><div id="container_5be47000"><div id="container_5be47000_padding" >
                                <div className="textstyle2">  <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>โปรแกรมบริหารคลินิกความงาม:</span>
                                    <span className="textstyle10"><br/></span>
                                    {/* <span className="textstyle12"> */}
                                                ใช้สำหรับบริหารคลินิกความงาม ซึ่งจะมีการค้นหาประวัติลูกค้า การเงิน นัดหมาย เลือกรูปมาเปรียนเทียบ(ก่อน-หลัง) ประวัติพนักงาน การค้นหาข้อมูลต่างๆเพื่อแก้ไขหรือตรวจวิเคราะหข้อมูล 
                                                จัดเก็บข้อมูลในแต่ละวัน บันทึกคอร์ส ลงเวลาทำงานของพนักงาน สต็อกยาและเวชภัณฑ์ต่างๆ ออกใบเสร็จรับเงินให้ลูกค้า 
                                                สแกนบาร์โค๊ดสินค้าหรือเวชภัณฑ์ พิมพ์สติกเกอร์ ส่วนแบ่งผู้ให้บริการ ส่วนแบ่งนายหน้า 
                                                ในแต่ละครั้งสามารถจ่ายเงินแบบเงินสด-เครดิต-เงินโอน-QRPay รวมกันได้ ระบบการแบ่งจ่าย(มัดจำ), OPD Card, SMS Marking เพื่อประมวลผลข้อมูลให้มีประสิทธิ์ภาพทั้งง่าย รวดเร็ว 
                                                สะดวกและฟังก์ชั่นอื่นๆ อีกหลายอย่าง  <br/>
                                        <hr/>
                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'right'}}>
                                                <img className="img-fluid" src="assets\images/SBeauty\SBeautyP.png" width="530" height="344" alt="" /> 
                                            </div>
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> รูปภาพและการค้นหาประวัติ</font></strong><br/>
                                                บันทึกรูปภาพได้ไม่จำกัดและแสดงรูปได้ตามจำนวนที่บันทึกไว้ 
                                                บันทึกประวัติอัตโนมัติจากเครื่องอ่านบัตรโดยง่ายและสะดวก  
                                                แสดงข้อมูลสุขภาพ ประวัติบริการ/ซื้อเวชภัณฑ์ 
                                                มี OPDและส่ง SMSและจัดการนัดหมาย <br/><br/>
                                            </div>	
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'right'}}>
                                                <img className="img-fluid" src="assets\images\SBeauty\CompareBA.png" width="530" height="344" alt="" />     
                                            </div>
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                    <strong><font style={{'font-size': '20px'}}> เปรียบเทียบรูปภาพ ก่อน-หลัง</font></strong><br/>
                                                        เลือกรูปภาพต่างๆมาทำการเปรียนเทียบ(ก่อน-หลัง)ได้ง่าย 
                                                        และทำการซูมภาพให้ ย่อ-ขยายตามจุดที่ต้องการได้ 
                                                        นำรูปอื่นๆที่ไม่ได้อยู่ในที่เก็บรูปของคนไข้มาเปรียบเทียบได้ 
                                                        หรือจะเขียนข้อความหรือระบายสีต่างๆลงบนรูปเก็บไว้ได้<br/><br/>
                                            </div>
                                        </div>
                                        <hr/>
                                    
                                        {/* <!-- Pricing Section --> */}
                                        <div className="row" style={{'background-color':'lightgray'}}>

                                                <div className="col-md-12 col-sm-12">
                                                    <div className="section-title">
                                                        <p className="textstyle10" align="center">ราคา/แพ็กเกจ โปรแกรมคลินิกความงาม</p>
                                                        <p align="center"><font size="+1">คุณสมบัติของโปรแกรม แพ็กเกจBusinessและแพ็กเกจAdvanced จะทำงานเหมือนกันในแบบออฟไลน์ซึ่งไม่ต้องใช้ Internet 
                                                        แต่แพ็กเกจAdvanced จะมีข้อความแจ้งเตือนรายงานต่างๆ เช่น แจ้งเตือนนัดหมายคนไข้ แจ้งเตือนนัดหมายคุณหมอ แจ้งเตือนยกเลิกใบเสร็จ รายงายรายรับ-รายจ่ายประจำวัน ทาง LINE Notify ได้จากทุกอุปกรณ์ผ่านมือถือระบบ iOS/Android หรือ Windows/Mac ได้ หากสงสัยหรือต้องการข้อมูลเพิ่มเติมกรุณาติดต่อเรา</font></p>
                                                        <h2 align="center"><font color="red">โปรโมชั่น </font><br /></h2>
                                                        <h3 align="center"> สำหรับคลินิกที่ลงโปรแกรม 3 จุดขึ้นไป <font color="red">#แถมฟรี </font>เครื่องอ่านบัตรปชช.หรือเครื่องสแกนลายนิ้ว 1 ชุด</h3>      
                                                    </div>
                                                    <hr />
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.6s">
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>  
                                                            <div className="card-header pricing-month">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>12,000 บาท</h2>
                                                            </div>
                                                            <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">Business</h3>
                                                            </div>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>ลงโปรแกรมฟรี 2 จุด</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>โปรแกรมทำงานแบบ Offline</p>                                                            
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวน บัญชีผู้ใช้งาน</p> 
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>   	 
                                                        </div>
   
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.8s">
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="card-header pricing-month text-white">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>18,000 บาท</h2>
                                                            </div>
                                                            <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">Advanced</h3>
                                                            </div>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>MS SQLServer</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Support Mobile/Cloud Backup</p>                                                            
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวน บัญชีผู้ใช้งาน</p>
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                                        </div>
                                                        	
                                            </div>

                                        </div>
                                    <p />    
                                    {/* <!-- END - Pricing Section -----------------------------------------------------------------> */}
                                    
                                    {/* </span> */}
                                </div>
                                <div className="textstyle1">  <span className="textstyle11"></span>
                                </div>
                                </div></div></div>
                                <div style={{clear:'both'}}></div></div>
                            </div>
                        {/* <!------------------------------------------------------------------------------------------------------>     */}
                        </div>
                    </div> 
                </section>  
        </>
    )
    }
}

export default SBeauty
