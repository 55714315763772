import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
// import "../../../public/css/grid.css";
import "./grid.css";

class  Prices extends Component  {
        
    render() {
    return (
        <>
            <section className="prices-section bg-white" id="prices">
                <div className="container px-4 px-lg-5">

                        <div className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                            <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>แพ็คเกจสำหรับคลินิก:</span><br />
                            <div className="container">
                                <div className="page-header">
                                    <h4 className="textstyle11">ขั้นตอนการซื้อโปรแกรม</h4>
                                </div>
                                <p className="textstyle12">1.ชำระเงินผ่านทางธนาคาร หรือเครื่อง ATM เข้าบัญชีธนาคาร</p>
                                <p className="textstyle12">2.แล้วส่งหลักฐานการโอนเงิน สลิปหรือถ่ายรูป มาที่อีเมล<font color=""> wanprosoft@gmail.com</font>, <font color="">wanprosoft@outlook.com</font> หรือส่งมาทาง LineID:<font color=""> workdental</font></p>
                                <p className="textstyle12">3.ทาง wanprosoft จะดำเนินการติดตั้งโปรแกรมให้ลูกค้าตามที่ได้ตกลงกันไว้</p>
                            </div>

                        </div>

                        <div className="col-md-12 col-sm-12" style={{'text-align':'center'}}>
                            <span className="textstyle10">เลือกธนาคาร</span>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <img src="assets\images\kbank.png" height="30" width="30" align="middle" alt="กสิกรไทย" />
                                        <span className ="textstyle12"> กสิกรไทย สาขาเทสโก้ โลตัส สุขาภิบาล 3 บจก.วรรณ์โปรซอฟต์ เลขที่บัญชี 041-3-96093-2 </span>
                                    </li>
                                </ol>
                            </nav>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <img src="assets\images\kSCB.png" height="30" width="30" align="middle" alt="ไทยพาณิชย์" />
                                        <span className ="textstyle12"> ไทยพาณิชย์ สาขารามคำแหง(หัวหมาก) เลขที่บัญชี 403-857644-3</span>
                                    </li>
                                </ol>
                            </nav>
                        </div>

                </div>
            </section>
            <p />
            {/* ------------------------------------------------------------------------------------------------------------------ */}
            {/* <!-- Pricing Section --> */}
            <section id="pricing">
                <div className="container" style={{'background-color':'lightgray'}}>
                    <div className="row">

                        <div className="col-md-12 col-sm-12">
                            <div className="section-title">
                                <p className="textstyle10" align="center">ราคา/แพ็กเกจ โปรแกรมคลินิกทันตกรรม</p>
                                <p align="center"><font size="+1">เลือกแพ็กเกจโปรแกรมให้ตรงกับขนาดของคลินิกและจำนวนทันตแพทย์ในคลินิก หากสงสัยหรือต้องการข้อมูลเพิ่มเติมกรุณาติดต่อเรา</font></p>
                                <h2 align="center">
                                    {/* <img src="assets\images\marketing.png" height="50" width="50" align="middle" alt="โปรโมชั่น" /> */}
                                    {/* <img src="assets\images\marketing_1.png" height="50" width="50" align="middle" alt="" /> */}
                                    {/* <font color="red">โปรโมชั่น ต้อนรับปี 2566</font><br /> */}
                                </h2>
                                <h3 align="center"> สำหรับแพ็กเกจ Business ที่ลง 2 จุดขึ้นไป <font color="red">#แถมฟรี </font>เครื่องอ่านบัตรปชช.หรือเครื่องพิมพ์ใบเสร็จความร้อน 1 ชุด พร้อมคู่มือการใช้โปรแกรม</h3>
                                {/* <h3 align="center"> สำหรับแพ็กเกจ Business ที่ลง 2 จุดขึ้นไป<font color="red"> #แถมฟรีอุปกรณ์ที่ใช้ร่วมกับโปรแกรม </font> -เครื่องอ่านบัตรปชช. -เครื่องพิมพ์ใบเสร็จความร้อน -เครื่องสแกนลายนิ้วมือ -เครื่องสแกนลายนิ้วมือ พร้อมคู่มือการใช้โปรแกรม  </h3> */}
                            </div>

                            {/*  */}
                           
                            <div className="container head-text">
                                <div className="row">
                                    {/* <img src="assets/images/Promotions2565.png" className="d-block w-100 card-img" alt="" /> */}
                                </div>
                            </div>
                            {/*  */}
                            <hr />
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4 cta hvr-float-shadow" data-wow-delay="0.4s">
                            <a className="tooltips">

                                <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                    <div className="card-header pricing-month">
                                        <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>9,900 บาท</h2>
                                    </div>
                                    <div className="pricing-title">
                                        <h3 className="textstyle10" align="center">Starter</h3>
                                    </div>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>Stand Alone System</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>per 1 unit</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>จุดต่อไปทุกสาขา/5,000</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Unlimited Data</p>
                                    <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                </div>
                                <span>เริ่มต้นครบครัน</span>
                            </a>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4 cta hvr-float-shadow" data-wow-delay="0.6s">
                            <a className="tooltips">

                                <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                    <div className="card-header pricing-month">
                                        <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>25,000 บาท</h2>
                                    </div>
                                    <div className="pricing-title">
                                        <h3 className="textstyle10" align="center">Business</h3>
                                    </div>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>MS SQL Server DB.</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>เพิ่มสาขาจุดแรก/20,000</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>จุดต่อไปทุกสาขา/5,000</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Unlimited Data</p>
                                    <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ครบทุกฟังก์ชันการทำงาน</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                </div>
                                <span>คุ้มค่า-ยอดนิยม</span>
                            </a>
                        </div>

                        <div className="wow fadeInUp col-md-4 col-sm-4 cta hvr-float-shadow" data-wow-delay="0.8s">
                            <a className="tooltips">

                                <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                    <div className="card-header pricing-month">
                                        <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>35,000 บาท</h2>
                                    </div>
                                    <div className="pricing-title">
                                        <h3 className="textstyle10" align="center">Business Plus</h3>
                                    </div>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>Cloud + Database</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>เพิ่มสาขาจุดแรก/20,000</p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>จุดต่อไปทุกสาขา/5,000</p>
                                    {/* <p className="textstyle11" align="center">Cloud Data/Support Mobile</p> */}
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Unlimited Data</p>
                                    <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                    <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>โอนข้อมูลเก่าให้ฟรี</font></p>
                                    <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                    {/* <p className="textstyle11" align="center">2,000/เดือน (<font style={{color:'#FF5500'}}>ฟรีค่าคลาวด์  1 ปี </font>)</p> */}
                                    
                                </div>
                                <span>ใช้งานผ่านคลาวด์</span>
                            </a>
                        </div>
                        {/* ------------------------------ AZURE ------------------------------------------------- */}
                        <div className="container-fluid col-md-12 col-sm-12" style={{'background-position':'right',background:'url("assets/images/Azurebg.png") no-repeat',height:'250px',width:'100%'}} align="right">
                            <div className="col-md-4 col-sm-4></div>
                            <div className="col-md-4 col-sm-4></div>
                            {/* <div className="container-fluid col-md-offset-2 col-md-12 col-sm-12" align="right" >     */}
                            {/* <a href="https://azure.microsoft.com/en-us/services/security-center/#overview/" target="_blank" className="cta-btn align-middle">อ่านเพิ่มเติม...</a> */}
                            {/* </div> */}
                            {/* <img className="img-responsive mx-auto d-block card-img" src="assets\images\Azurebg.png" alt="" /> */}
                            <div className="wow fadeInUp col-md-4 col-sm-4 cta hvr-float-shadow" data-wow-delay="0.6s" style={{'text-align':'left'}}>
                                <br />   
                                <strong><font style={{'font-size': '20px','color':'white',align:'center'}}>Cloud SQL Database</font></strong><br />
                                <font style={{color:'white',fontSize:14}}>เจ้าของไม่ต้องอยู่ที่คลินิกสก็สามารถเข้าโปรแกรมจัดการข้อมูลหรือดูรายงานต่างๆได้ ทำให้เจ้าของคลินิกประหยัดเวลาและค่าใช้จ่าย รวมถึงมีระบบรักษาความปลอดภัยของข้อมูล<br />
                                  <a class="btn btn-info" role="button" href="#"><font style={{color:'white',fontSize:12}}>รายละเอียดข้อมูลเพิ่มเติม..</font></a>
                                </font>
                            </div>
                        </div>
                        {/* -------------------------------------------------------------------------------------- */}
                    </div>

                </div>  
            </section>
            {/* <!-- END - Pricing Section -----------------------------------------------------------------> */}
            <p />
            <div style={{clear:'both'}}></div>
            {/* ------------------------------------------------------------------------------------------------------------ */}
            {/* <!-- CusSam Section --> */}
            <section id="CusSam"> 
                {/* GRID CSS */}  
                <div className="col-md-offset-2 col-md-12 col-sm-12" align="center"> 
                        <div className="section-title">
                            <span className="textstyle10" align="center">รายชื่อลูกค้า</span>
                            <p className="wow fadeInUp" data-wow-delay="0.8s" align="center">ตัวอย่างลูกค้าที่ใช้โปรแกรมในคลินิกทั่วประเทศ</p>
                        </div>
                </div>
                <div className="container">    
                    <div className="grid-container">
                        <div className="grid-items"> 
                            {/* โลโก้ กลุ่ม 1 */}
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\HappySmile.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\DentalLand.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\ChacrisClinic.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\NightSmile.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\KDC-Dental.png" alt="" />
                                </div>    
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\WeDent.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\Luxyim.png" alt="" />
                                </div>
                            </Carousel>  
                        </div>

                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 2 */}
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >    
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\MeanburiClinic.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\LeGrande.png" alt="" />    
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\mmt.png" alt="" />   
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\DrffDentalClinic.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\PSmile.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\IDentist.png" alt="" />
                                </div>
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic\MondPia.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 3 */}
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample1.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample2.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample3.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample4.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample5.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample6.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample7.png" alt="" />
                                </div>
                            </Carousel>   
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 4 */}    
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample8.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample9.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample10.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample11.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample12.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample13.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample14.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 5 */}    
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample15.png" alt="คลินิกทันตกรรมพรีม่า เดนทัลแคร์" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample16.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample17.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample18.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample19.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic1\CutSample20.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample21.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 6 */}    
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample22.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample23.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample24.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample25.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample26.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample27.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample28.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 7 */}    
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample29.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample30.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample31.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample32.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample33.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample34.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample35.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        <div className="grid-items">
                            {/* โลโก้ กลุ่ม 8 */}    
                            <Carousel nextIcon="" nextLabel="" prevIcon="" prevLabel="" >
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample36.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample37.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample38.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample39.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample40.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample41.png" alt="" />
                                </div>
                                {/* <!----------------------------------------------------------------> */}
                                <div className="carousel-item">
                                    <img className="img" src="assets\rc_images\LogoClinic2\CutSample42.png" alt="" />
                                </div>
                            </Carousel>
                        </div>
                        
                    </div>
                </div>
                {/* END GRID CSS */} 
            </section>
            {/* <!-- END - CusSam Section -----------------------------------------------------------------> */}
            
        </>
    )
}
}

export default Prices
