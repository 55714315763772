import React, { Component } from 'react'

class Download extends Component {

    // handleSubmit= (e) => {
    //     e.preventDefault(window.location.reload());
    //     console.log(window.location.reload())
    // }
    
    render() {
    return (
        <>
            <section id="Line-Notify" align="center">
                <div className="container" style={{'background-color':'#ffffff'}}>
                    <div className="row">

                        <div id="container_38dcaec" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                            <div id="container_38dcaec_padding" >
                                <div className="textstyle2">
                                    <div id="container_66675b11">
                                        <div id="container_66675b11_padding" >
                                            <div className="textstyle2">
                                                <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>ข้อความแจ้งเตือน:</span><br />
                                                <span className="textstyle12">บริการแจ้งเตือนรายงานต่างๆทาง LINE Notify  <br />
                                                </span>
                                            </div>

                                            {/* <!-- Download --> */}
                                            <div className="container" style={{'background-color':'lightgray'}}>
                                                <div className="row">

                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="section-title">
                                                            <span className="textstyle3"> &nbsp; </span>
                                                        </div>
                                                    </div>

                                                    <div className="wow fadeInUp col-md-4 col-sm-8" data-wow-delay="0.4s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px',padding:'15px'}}>
                                                            <div className="pricing-month" align="left">
                                                                <h2 className="textstyle10" align="center">บริการแจ้งเตือนต่างๆ</h2>
                                                                <p align="center"><b>รายงานทุกการความเคลื่อนไหวของคลินิก</b></p>
                                                                📢 ทำให้เจ้าของคลินิกหรือเจ้าของร้านค้ารับรู้รายงานการแจ้งเตือนทุกการเคลื่อนไหวผ่านทาง LINE Notify เช่น มีการแจ้งเตือนนัดหมายไปยังคนไข้ แจ้งเตือนนัดหมายของคุณหมอ
                                                                แจ้งเตือนการยกเลิกใบเสร็จ แจ้งเตือนรายรับ-รายจ่ายประจำวัน แจ้งเตือนสต๊อกใกล้หมดและแจ้งเตือนข่าวสารต่างๆจากคลินิกหรือร้านค้า
                                                                ทำให้คนไข้หรือลูกค้าได้รับการแจ้งเตือนข่าวสารที่ถูกต้องและรวดเร็ว<br />
                                                                -------------------------<br />
                                                                👉ติดต่อสอบถาม :<br />
                                                                .....📞 081-5837210<br />
                                                                .....📧 wanprosoft@gmail.com<br />
                                                                -------------------------<br />
                                                                👉ดูรายละเอียดเพิ่มเติมได้ที่ :<br />
                                                                    {'>>🌐'}<a href="https://workdentals.com/" className="btn btn-light">www.workdentals.com</a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="wow fadeInUp col-md-8 col-sm-8" data-wow-delay="0.4s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px',padding:'0px'}}>
                                                            <div className="pricing-month" align="center">
                                                                <img className="card-img-top" src="assets\images\Line-Notify.png" alt="" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <!-- END - Download -----------------------------------------------------------------> */}

                                        </div>
                                    </div>
                                </div>

                                <div style={{clear:'both'}}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!--------------------------------- Select Line Notify --------------------------------------------------------------> */}
            <p />
            <section id="cloud" align="center">
                <div className="container" style={{'background-color':'#ffffff'}}>
                    <div className="row">

                        <div id="container_38dcaec" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                            <div id="container_38dcaec_padding" >
                                <div className="textstyle2">
                                    <div id="container_66675b11">
                                        <div id="container_66675b11_padding" >
                                            <div className="textstyle2">
                                                <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>คลาวด์:</span><br />
                                                <span className="textstyle12">Backup Database ที่สำคัญไว้ในคลาวด์โดยอัตโนมัติ<br />
                                                </span>
                                            </div>

                                            {/* <!-- Download --> */}
                                            <div className="container" style={{'background-color':'lightgray'}}>
                                                <div className="row">

                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="section-title">
                                                            <span className="textstyle3"> &nbsp; </span>
                                                        </div>
                                                    </div>

                                                    <div className="wow fadeInUp col-md-4 col-sm-8" data-wow-delay="0.4s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px',padding:'15px'}}>
                                                            <div className="pricing-month" align="left">
                                                                <h2 className="textstyle10" align="center">บริการจัดเก็บดาต้าเบส</h2>
                                                                <p align="center"><b>ป้องกันข้อมูลจากเหตุการณ์ที่ไม่คาดฝัน!!</b></p>
                                                                📢 กรณีลูกค้ารายปีจะติดตั้งให้ฟรีไม่มีค่าใช้จ่าย ..ส่วนลูกค้าที่ไม่ได้ทำสัญญาบริการรายปี มีค่าใช้จ่ายเฉพาะการติดตั้งครั้งแรก<br />
                                                                -------------------------<br />
                                                                👉ติดต่อสอบถาม :<br />
                                                                .....📞 081-5837210<br />
                                                                .....📧 wanprosoft@gmail.com<br />
                                                                -------------------------<br />
                                                                👉ดูรายละเอียดเพิ่มเติมได้ที่ :<br />
                                                                {'>>🌐'}<a href="https://workdentals.com" className="btn btn-light">www.workdentals.com</a>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="wow fadeInUp col-md-8 col-sm-8" data-wow-delay="0.4s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px','padding':'0px'}}>
                                                            <div className="pricing-month" align="center">
                                                                <img className="card-img-top" src="assets\images\sdent_cloud.png" alt="" />
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <!-- END - Download -----------------------------------------------------------------> */}

                                        </div>
                                    </div>
                                </div>

                                <div style={{clear:'both'}} />
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <p />
            {/* ----------------------------------------------------------------------------------------------------------------------- */}
            {/* <!----------------------------- section Dowload -----------------------------------------------> */}
            <section id="Dowload" align="center">
                <div className="container" style={{'background-color':'#ffffff'}}>
                    <div className="row">

                        <div id="container_38dcaec" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                            <div id="container_38dcaec_padding" >
                                <div className="textstyle2">
                                    <div id="container_66675b11">
                                        <div id="container_66675b11_padding" >
                                            <div className="textstyle2">
                                                <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>ดาวน์โหลด:</span><br />
                                                <span className="textstyle12">โปรแกรมเสริมและไดรเวอร์ต่างๆที่ใช้ร่วมกัน <br />
                                                </span>
                                            </div>

                                            {/* <!-- Download --> */}
                                            <div className="container" style={{'background-color':'lightgray'}}>
                                                <div className="row">

                                                    <div className="col-md-12 col-sm-12">
                                                        <div className="section-title">
                                                            <span className="textstyle3"> &nbsp; </span>
                                                        </div>
                                                    </div>

                                                    <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.4s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="pricing-month" align="center">
                                                                <h2 className="textstyle10" align="center"> Teamviewer </h2>
                                                                <img className="card-img-top" src="assets\images\teamviewer.png" alt="" />
                                                                <a href="assets\images\TeamViewerQS.exe" className ="btn btn-light" >Download</a>
                                                            </div>

                                                        </div>
                                                        <p />
                                                    </div>

                                                    <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.6s">

                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="pricing-month" align="center">
                                                                <h2 className="textstyle10" align="center">Finger scan</h2>
                                                                <img className="card-img-top img-fluid" src="assets\images\ZK4500.png" alt="" />
                                                                <a href="assets\download\Fingerscan.rar" className ="btn btn-light">Download</a>
                                                            </div>

                                                        </div>
                                                        <p />
                                                    </div>

                                                    <div className="wow fadeInUp col-md-4 col-sm-4" data-wow-delay="0.8s">
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="pricing-month" align="center">
                                                                <h2 className="textstyle10" align="center"> Smart card </h2>
                                                                <img className="card-img-top img-fluid" src="assets\images\rd-comp.png" alt="" />
                                                                <a href="assets/download/Smartcard.rar" className ="btn btn-light">Download</a>
                                                            </div>

                                                        </div>
                                                        <p />
                                                    </div>

                                                </div>
                                            </div>
                                            {/* <!-- END - Download -----------------------------------------------------------------> */}

                                        </div>
                                    </div>
                                </div>

                                <div style={{clear:'both'}}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <p />
  
        </>
    )
    }
}

export default Download
