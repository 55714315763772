import React, { Component } from 'react'
import { useState } from 'react';

class Contact extends Component {
    
    render() {
    return (
        <>
            {/* <section class="contact-section bg-black">
                <div class="container px-4 px-lg-5">
                    <div class="row gx-4 gx-lg-5">
                        <div class="col-md-4 mb-3 mb-md-0">
                            <div class="card py-4 h-100">
                                <div class="card-body text-center">
                                    <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                                    <h4 class="text-uppercase m-0">Address</h4>
                                    <hr class="my-4 mx-auto" />
                                    <div class="small text-black-50">4923 Market Street, Orlando FL</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-md-0">
                            <div class="card py-4 h-100">
                                <div class="card-body text-center">
                                    <i class="fas fa-envelope text-primary mb-2"></i>
                                    <h4 class="text-uppercase m-0">Email</h4>
                                    <hr class="my-4 mx-auto" />
                                    <div class="small text-black-50"><a href="#!">hello@yourdomain.com</a></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3 mb-md-0">
                            <div class="card py-4 h-100">
                                <div class="card-body text-center">
                                    <i class="fas fa-mobile-alt text-primary mb-2"></i>
                                    <h4 class="text-uppercase m-0">Phone</h4>
                                    <hr class="my-4 mx-auto" />
                                    <div class="small text-black-50">+1 (555) 902-8832</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="social d-flex justify-content-center">
                        <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                        <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                        <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
                    </div>
                </div>
            </section> */}
            {/* -------------------------------------------------------------------------------------- */}
            <section className="Contact-section bg-white" id="Contact">
                <div className="container">
                    <div className="row">

                        <div id="container_11641a92" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                            <div id="container_11641a92_padding" ><div className="textstyle2"><div id="container_5be47000"><div id="container_5be47000_padding" >
                                <div className="textstyle2">  <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>ติดต่อ-สอบถาม:</span>
                                    <span className="textstyle10"><br /></span>
                                    <span className="textstyle12">เวลาทำการ 9:00 – 18:00 น. <br />
                                    {/* <span className="textstyle12">เวลาทำการ 9:00–18:00 น. วันจันทร์-วันศุกร์</span><br /> */}
                                    <span className="textstyle12">ติดต่อสอบถามเกี่ยวกับโปรแกรมได้ตลอดเวลา</span><br />
                                    <span className="textstyle12">บริการแก้ไขปัญหาโปรแกรมตลอด 24 ชั่วโมง</span><br /><br />
                                   

                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> บริษัท วรรณ์โปรซอฟต์ จำกัด</font></strong><br />
                                                88/65 ปรัญญาโฮมทาวส์ ถนนเสรีไทย <br />
                                                แขวงมีนบุรี เขตมีนบุรี <br />
                                                กรุงเทพมหานคร 10510 <br />
                                                Email: wanprosoft@gmail.com<br />
                                                Tel : 081-583-7210 หรือ 085-133-2576<br /><br />
                                            </div>
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> เกี่ยวกับเรา</font></strong><br />
                                                เรามอบ Solutions เพื่อพัฒนาธุรกิจของคุณ <br />
                                                เพื่อใช้เป็นวิธีการแก้ปัญหาทางธุรกิจเฉพาะด้าน <br />
                                                ทำให้องค์กรของคุณมีประสิทธิภาพมากขึ้น <br />
                                                และดำเนินงานไปได้อย่างสะดวก รวดเร็วและถูกต้อง<br /><br />
                                            </div>
                                        </div>

                                        <a href="https://fb.me/SDent7Software" target="_blank"><img src="assets\images\facebook.jpg" title="ติดตามบน Facebook" border="0" alt="" /></a>&nbsp;&nbsp;
                                        <a href="https://m.me/SDent7Software" target="_blank"><img src="assets\images\messenger.png" title="Facebook messenger" border="0" width="45" height="45" alt="" /></a>&nbsp;&nbsp;
                                        <a href="https://line.me/ti/p/VO6lYW94Fv" target="_blank" ><img src="assets\images\addfriends.png" className="img-circle" title="Line : QRCode" border="0" width="45" height="45" alt="" /></a>
                                        <br /><br />

                                        <p><strong>Note:</strong>  รับพัฒนาโปรแกรมเพิ่มเติมตามที่ลูกค้าต้องการ หรือเพื่อตามความเหมาะสมของคลินิกทันตกรรมและระบบงานอื่นๆ</p>

                                    </span>
                                </div>
                                <div className="textstyle1">  <span className="textstyle11"></span>
                                </div>
                            </div></div></div>
                                <div style={{clear:'both'}}></div></div>
                        </div>

                    </div>
                </div>
            </section>
            {/* <!-------------- Map  ----------------> */}
            <iframe src="https://www.google.com/maps/d/u/0/embed?mid=zflFwlDe-3e8.kQVGibvA7Knw" width="100%" height="300" frameborder="0" />
 
        </>
    )
    }
}

export default Contact
