import React,{ Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from "../components/pages/Home";
import Prices from "../components/pages/Prices";
import Download from "../components/pages/Download";
import Contact from "../components/pages/Contact";
import SBeauty from '../components/pages/SBeauty';
import Spos from '../components/pages/Spos';
import SCRM from '../components/pages/SCRM';
// import Signup from "../components/pages/Signup";
// import LogIn from '../components/auth/LogIn';
// import SignUp from '../components/auth/SignUp';

class index extends Component {    
    render() {
      return(  
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/Prices" component={Prices} />
            <Route path="/Download" component={Download} />
            <Route path="/Contact" component={Contact} />
            <Route path="/SBeauty" component={SBeauty} />
            <Route path="/Spos" component={Spos} />
            <Route path="/SCRM" component={SCRM} />
            {/* <Route path="/LogIn" component={LogIn} /> */}
            {/* <Route path="/SignUp" component={SignUp} /> */}
          </Switch>
      )
    }
}    

export default index;