import React, { Component } from 'react'

class SCRM extends Component {

    render() {
    return (
        <>
            <section className="SCRM-section bg-white" id="SCRM">
                    <div className="container">
                        <div className="row">

                            <div id="container_11641a92" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                                <div id="container_11641a92_padding" ><div className="textstyle2"><div id="container_5be47000"><div id="container_5be47000_padding" >
                                <div className="textstyle2">  <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>โปรแกรมบริหารงานกำจัดปลวก:<br/>(ปลวก แมลงสาบ มด ยุง งู หนู) </span>
                                    <span className="textstyle10"><br/></span>
                                    <span className="textstyle12">
                                                ใช้สำหรับบริหารงานลูกค้าที่ทำสัญญาบริการกับบริษัท เช่นมีการแจ้งเตือนสัญญาที่ใกล้สิ้นสุดหรือแจ้งเตือนนัดหมายบริการ ประวัติลูกค้า สต็อกผลิตภัณฑ์ เพื่อช่วยให้การบริการของบริษัทสะดวกและรวดเร็วมากขึ้น  
                                                ซึ่งสามารถเช็คสต๊อกผลิตภัณฑ์คงเหลือ การรับ-เบิกผลิตภัณฑ์จากคลังรวมถึงรายงานต่างๆ เช่น สรุปบัญชีรายรับแต่ละ(วัน-สัปดาห์-เดือน-ปี) 
                                                และยังมีฟังก์ชั่นอื่นๆ อีกหลายอย่าง<br/>
                                        {/* <hr/> */}<br/>
                                        <div className="row">
                                            <div className="container-fluid text-center">
                                                <span className="textstyle10">หน้าจอหลัก</span>
                                                {/* <p className="wow fadeInUp" data-wow-delay="0.8s">ตัวอย่างหน้าจอบางส่วนของโปรแกรม <br />
                                                <font style={{color:'Red'}}> ใช้ง่ายครบทุกฟังก์ชัน</font> ประสบการณ์มากกว่า 15 ปี มีใช้ในคลินิกทั่วประเทศ
                                                </p> */}
                                            </div>

                                            <div className="container" style={{margin:"10px"}}>
                                                <div className="row">
                                                {/* width="1210" height="338" */}
                                                <img src="assets\images\SCrm\SCRM-Main.png" className="d-block w-100 card-img" alt="" />
                                                </div>
                                            </div>

                                            {/* <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> หน้าจอโปรแกรมหลัก</font></strong><br/>
                                                โปรแกรมจัดระเบียบเมนูต่างๆให้ใช้งานง่ายและดูสบายตา ทำให้การเรียนรู้การใช้โปรแกรมทำได้ง่ายและรวดเร็ว 
                                                และการเรียกหน้าจอต่างๆมาใช้งานทำได้อย่างรวดเร็ว <br/><br/>
                                            </div>	 */}
                                        </div>
                                        <hr />
                                        {/* ----------------------------------------------------------------------------------------- */}
                                        <div className="row">
                                            <div className="container-fluid text-center">
                                                <span className="textstyle10">หน้าจอประวัติลูกค้า</span>
                                            </div>

                                            <div className="container" style={{margin:"10px"}}>
                                                <div className="row">
                                                <img src="assets\images\SCrm\SCRM-Customer-M.png" className="d-block w-100 card-img" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {/* ----------------------------------------------------------------------------------------- */}
                                        <div className="row">
                                            <div className="container-fluid text-center">
                                                <span className="textstyle10">หน้าจอนัดหมายบริการ</span>
                                            </div>

                                            <div className="container" style={{margin:"10px"}}>
                                                <div className="row">
                                                <img src="assets\images\SCrm\SCRM-Recall-M.png" className="d-block w-100 card-img" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        {/* ---------------------------------------------------------------------------------------------- */}
                                        {/* <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12">
                                                <p><span style={{'color': '#ff6600', 'font-size': '12pt;'}}><strong>สต๊อคพัสดุ</strong></span></p>
                                                <ul>
                                                    <li><span style={{'font-size': '12pt'}}>จัดการสต๊อคสินค้า</span></li>
                                                    <li><span style={{'font-size': '12pt'}}>บันทึกรับสินค้าเข้าสต๊อค</span></li>
                                                    <li><span style={{'font-size': '12pt'}}>บันทึกเบิกจ่ายพัสดุ</span></li>
                                                    <li><span style={{'font-size': '12pt'}}>บันทึกส่งคืนพัสดุ</span></li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        {/* ---------------------------------------------------------------------------------------------- */}
                                        {/* <!-- 16:9 aspect ratio --> */}
                                        {/* <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/LZTBW_-vkck" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div> */}
                                        {/* <hr/>              */}

                                        {/* <!-- Pricing Section --> */}
                                        <div className="row" style={{'background-color':'lightgray'}}>

                                                <div className="col-md-12 col-sm-12">
                                                    <div className="section-title">
                                                        <p className="textstyle10" align="center">ราคา/แพ็กเกจ โปรแกรมบริหารงานกำจัดปลวก</p>
                                                        <p align="center"><font size="+1">โปรแกรมสามารถส่งข้อความแจ้งเตือนทางไลน์ไปให้ลูกค้าหรือผู้ให้บริการหรือช่างประจำบริษัทได้ หากสงสัยหรือต้องการข้อมูลเพิ่มเติมกรุณาติดต่อเรา</font></p>
                                                        {/* <h2 align="center"><font color="red">โปรโมชั่น </font><br /></h2> */}
                                                        {/* <h3 align="center"> สำหรับมินิมาร์ทหรือร้านค้าที่ลงโปรแกรม 2 จุดขึ้นไป <font color="red">#แถมฟรี </font>เครื่องยิงบาร์โค้ด 1 ชุด </h3>       */}
                                                    </div>
                                                    <hr />
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.6s">
                                                    <a className="tooltips"> 
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>  
                                                            <div className="card-header pricing-month">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>15,000 บาท</h2>
                                                            </div>
                                                            {/* <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">ชุดโปรแกรม SPOS อย่างเดียว</h3>
                                                            </div> */}
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>ระบบเครือข่าย 2 จุด</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>จุดต่อไป/2,000</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวนบัญชีผู้ใช้งาน</p> 
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>    
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>	 
                                                        </div>
                                                    </a>    
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.8s">
                                                    <a className="tooltips">
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="card-header pricing-month text-white">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>ซัพพอร์ตรายปี</h2>
                                                            </div>
                                                            {/* <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">5000/ปี</h3>
                                                            </div> */}
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>5000/ปี</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวนเครื่อง</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>ไม่จำกัดการเปลี่ยนเครื่อง</p>                                                           
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายรายปี</font></p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                                        </div>
                                                        
                                                    </a>  	
                                            </div>

                                        </div>
                                    <p />    
                                    {/* <!-- END - Pricing Section -----------------------------------------------------------------> */}
                                    
                                    </span>
                                </div>
                                <div className="textstyle1">  <span className="textstyle11"></span>
                                </div>
                                </div></div></div>
                                <div style={{clear:'both'}}></div></div>
                            </div>
                        {/* <!------------------------------------------------------------------------------------------------------>     */}
                        </div>
                    </div> 
                </section>     
        </>
    )   
    }
}

export default SCRM
