import React from 'react'
import Header from "./components/layouts/Header"
import Footer from "./components/layouts/Footer"

import Routing from './routes/index'
import { BrowserRouter as Router, Link } from "react-router-dom"
// import { Callbacks } from 'jquery'

function App() {
     return (
        <Router>
           <div className="App">
              <Header />
              <Routing />
              <Footer />
           </div>   
        </Router>  
        
     )
}

export default App;
