import React, { Component } from 'react'

class Spos extends Component {

    render() {
    return (
        <>
            <section className="Spos-section bg-white" id="Spos">
                    <div className="container">
                        <div className="row">

                            <div id="container_11641a92" className="col-md-12 col-sm-12" style={{'text-align':'left'}}>
                                <div id="container_11641a92_padding" ><div className="textstyle2"><div id="container_5be47000"><div id="container_5be47000_padding" >
                                <div className="textstyle2">  <span className="textstyle10" style={{color: 'rgb(6, 86, 88)'}}>โปรแกรมร้านมินิมาร์ท:</span>
                                    <span className="textstyle10"><br/></span>
                                    <span className="textstyle12">
                                                ใช้สำหรับบริหารร้านค้าต่างๆ เข่น ร้านมินิมาร์ท ร้านค้าปลีก ร้านโชว์ห่วย ร้านค้าทั่วไปหรือร้านค้าที่มีรถตู้ทึบหรือรถขายของเคลื่อนที่ตามจุดต่างๆ เพื่อช่วยให้การขายสินค้าของร้านสะดวกและรวดเร็วมากขึ้น  
                                                ซึ่งสามารถเช็คได้ว่าสินค้าตัวไหนขายดี สร้างคลังย่อยได้ไม่จำกัด เช็คสต๊อกสินค้าคงเหลือ การรับ-เบิกสินค้าจากคลังสินค้า สรุปการขายสินค้าในแต่ละวัน สรุปกำไร-ขาดทุน
                                                และมีระบบช่วยในการส่งเสริมการขายสินค้า โดยจะวิเคราะห์ข้อมูลออกมาในเชิงตัวเลขและกราฟ
                                                และยังมีฟังก์ชั่นอื่นๆ อีกหลายอย่าง<br/>
                                        <hr/>
                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'right'}}>
                                                <img className="img-fluid" src="assets\images\SPos\SPos1.png" width="530" height="344" alt="" /> 
                                            </div>
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> หน้าจอโปรแกรมหลัก</font></strong><br/>
                                                โปรแกรมจัดระเบียบเมนูต่างๆให้ใช้งานง่ายและดูสบายตา ทำให้การเรียนรู้การใช้โปรแกรมทำได้ง่ายและรวดเร็ว 
                                                และการเรียกหน้าจอต่างๆมาใช้งานทำได้อย่างรวดเร็ว <br/><br/>
                                            </div>	
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'right'}}>
                                                <img className="img-fluid" src="assets\images\SPos\SPos2.png" width="530" height="344" alt="" />    
                                            </div>	
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> หน้าจอขายสินค้า</font></strong><br/>
                                                ขายสินค้าด้วยระบบบาร์โค้ดหรือระบบค้นหาด้วยรหัสสินค้า โปรแกรมจะตัดสต๊อกโดยอัตโนมัติและสามารถตัดสต๊อกสินค้าด้วยระบบการผูกหน่วยสินค้าได้ 
                                                โปรแกรมจะทำการเตือนด้วยเสียงกรณีสแกนบาร์โค้ดไม่เข้าในระบบการขาย หรือจะเตือนในกรณีสินค้าไม่มีในสต๊อก มีระบบพักบิลและเรียกบิลมาขายต่อได้
                                                จะขายแบบสมาชิกหรือทั่วไปได้ การจ่ายเงินทั้งแบบ-เงินสด-เงินโอน-บัตรเครดิต-QRPay และออกบิลใบเสร็จได้ทั้งแบบสลิปและA4 มีระบบการแจ้งเตือนทางไลน์เมื่อเก็บเงินลูกค้า<br/><br/>
                                            </div>  
                                        </div>
                                        <hr/>
                                        <div className="row">
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'right'}}>
                                                <img className="img-fluid" src="assets\images\SPos\SPos3.png" width="530" height="344" alt="" /> 
                                            </div>
                                            <div id="container_11641a92" className="col-md-6 col-sm-12" style={{'text-align':'left'}}>
                                                <strong><font style={{'font-size': '20px'}}> หน้าจอวิเคราะห์การขาย</font></strong><br/>
                                                วิเคราะห์ข้อมูลเพื่อส่งเสริมการขาย วิเคราะห์สินค้าตัวไหมขายดีหรือสินค้าตัวไหมขายไม่ดี ลูกค้าหรือสมาชิกคนไหนซื้อสินค้าเท่าไร
                                                ช่วงเวลาไหนขายดีที่สุด ยอดขายสินค้าเพิ่มขึ้นหรือลดลงเมื่อเทียบกับเดือนที่ผ่านมา ทำให้เกิดประสิทธิภาพในการบริหารงาน <br/><br/>
                                            </div>	
                                        </div>

                                        <hr/>
                                        {/* <!-- 16:9 aspect ratio --> */}
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe width="560" height="315" src="https://www.youtube.com/embed/LZTBW_-vkck" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        <hr/>             

                                        {/* <!-- Pricing Section --> */}
                                        <div className="row" style={{'background-color':'lightgray'}}>

                                                <div className="col-md-12 col-sm-12">
                                                    <div className="section-title">
                                                        <p className="textstyle10" align="center">ราคา/แพ็กเกจ โปรแกรมมินิมาร์ท</p>
                                                        <p align="center"><font size="+1">คุณสมบัติของโปรแกรม รองรับการใช้งานร่วมกับ -เครื่องยิงบาร์โค้ด -ลิ้นชักเก็บเงิน -เครื่องพิมพ์ใบเสร็จอย่างย่อ -จอแสดงราคาสินค้า จอที่ 2 
                                                        และมีข้อความแจ้งเตือนรายงานต่างๆ เช่น แจ้งเตือนสินค้าใกล้หมด แจ้งเตือนยกเลิกใบเสร็จ รายงายรายรับ-รายจ่ายประจำวัน ทาง LINE Notify ได้จากทุกอุปกรณ์ผ่านมือถือระบบ iOS/Android หรือ Windows/Mac ได้ หากสงสัยหรือต้องการข้อมูลเพิ่มเติมกรุณาติดต่อเรา</font></p>
                                                        <h2 align="center"><font color="red">โปรโมชั่น </font><br /></h2>
                                                        <h3 align="center"> สำหรับมินิมาร์ทหรือร้านค้าที่ลงโปรแกรม 2 จุดขึ้นไป <font color="red">#แถมฟรี </font>เครื่องยิงบาร์โค้ด 1 ชุด </h3>      
                                                    </div>
                                                    <hr />
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.6s">
                                                    <a className="tooltips"> 
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>  
                                                            <div className="card-header pricing-month">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>15,000 บาท</h2>
                                                            </div>
                                                            <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">ชุดโปรแกรม SPOS อย่างเดียว</h3>
                                                            </div>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>มีรายการสินค้าในชุดโปรแกรม</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Support Mobile/Cloud Backup</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวน บัญชีผู้ใช้งาน</p> 
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>    
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>	 
                                                        </div>
                                                    </a>    
                                            </div>

                                            <div className="wow fadeInUp col-md-6 col-sm-6 cta hvr-float-shadow" data-wow-delay="0.8s">
                                                    <a className="tooltips">
                                                    
                                                        <div className="pricing-plan" style={{'background-color':'#FFFFFF','border-radius':'5px'}}>
                                                            <div className="card-header pricing-month text-white">
                                                                <h2 className="textstyle11" align="center" style={{color: 'rgb(6, 86, 88)'}}>45,000 บาท</h2>
                                                            </div>
                                                            <div className="pricing-title">
                                                                <h3 className="textstyle10" align="center">ชุดโปรแกรม SPOS พร้อมอุปกรณ์</h3>
                                                            </div>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>มีรายการสินค้าในชุดโปรแกรม</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>Support Mobile/Cloud Backup</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>เครื่องโน๊ตบุ๊ค 1 ชุด, เครื่องปริ้นสลิป</p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:20}}>เครื่องยิงบาร์โค้ด, ลิ้นชักเก็บเงิน </p>                                                            
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>ไม่จำกัดจำนวน บัญชีผู้ใช้งาน</p>
                                                            <p className="textstyle11" align="center"><font style={{color:'#FF5500',fontSize:19}}>จ่ายครั้งเดียว</font></p>
                                                            <p className="textstyle11" align="center" style={{textAlign:'center',fontSize:19}}>**ติดต่อเพื่อแนะนำแพคเกจ**</p>
                                                        </div>
                                                        
                                                    </a>  	
                                            </div>

                                        </div>
                                    <p />    
                                    {/* <!-- END - Pricing Section -----------------------------------------------------------------> */}
                                    
                                    </span>
                                </div>
                                <div className="textstyle1">  <span className="textstyle11"></span>
                                </div>
                                </div></div></div>
                                <div style={{clear:'both'}}></div></div>
                            </div>
                        {/* <!------------------------------------------------------------------------------------------------------>     */}
                        </div>
                    </div> 
                </section>     
        </>
    )   
    }
}

export default Spos
